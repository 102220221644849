import styled from "styled-components";
import AppleLogin from "react-apple-login";
import { useMediaQuery } from "react-responsive";
import FacebookLogin from "@greatsumini/react-facebook-login";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { preloadImage } from "modules/functions";
import ModalWindow from "components/other/ModalWindow";
import InputText from "components/forms/InputText";
import SocialButton from "components/buttons/SocialButton";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import InputWithLabel from "components/forms/InputWithLabel";

import scalelupLogo from "assets/img/logo/scalelup.svg";
import successIcon from "assets/img/icons/icons8-success.svg";
import emailIcon from "assets/img/icons/envelope-icon-gold.svg";

const Signup = styled.div`
  width: 100%;

  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignupForm = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrap = styled.div`
  width: 150px;
  padding: 5px 0 20px;
`;

const Heading = styled.div`
  font-size: 24px;
`;

const Details = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  max-width: 260px;

  text-align: center;
  line-height: 125%;
`;

const SocialButtons = styled.div`
  margin-top: 30px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${css.BUTTONS_GAP};

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }
`;

const DelimiterWrap = styled.div`
  margin-top: 20px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DelimiterLine = styled.div`
  width: 45%;

  border-bottom: 1px solid ${css.TEXT_GREY};
`;

const DelimiterText = styled.div`
  font-size: 11px;
  color: ${css.TEXT_GREY};
`;

const EmailForm = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
`;

const EmailFormControls = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignUpInfo = styled.div`
  margin-top: 30px;
`;

const LoginLink = styled.span`
  padding-left: 1px;

  font-weight: 500;
  color: ${css.LIGHT_BLUE};

  transition: 0.2;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`;

const ErrorText = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  max-width: 340px;

  font-weight: 500;
  color: ${css.ERROR_RED};
`;

const SignupSuccessInfo = styled.div`
  margin-top: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessIcon = styled.div`
  margin-top: 45px;
  width: 75px;
  height: 75px;
`;

const SuccessText = styled.div`
  margin-top: 10px;

  font-size: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 125%;
`;

const EmailInfo = styled.div`
  width: 100%;
  max-width: 380px;
  margin-top: 55px;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  border-radius: ${css.BOX_RADIUS_SECONDARY};
  background-color: ${css.FLAT_BOX_BACKGROUND};

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const EmailInfoTop = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 13px;
`;

const EmailInfoIconWrap = styled.div`
  padding: 0 10px;
`;

const EmailInfoIcon = styled.div`
  width: 40px;
  height: 40px;
`;

const EmailInfoText = styled.div``;

const EmailInfoSpamInfo = styled.div`
  font-size: 13px;
  text-align: center;
  line-height: 125%;
`;

const TermsText = styled.div`
  margin-top: 20px;

  font-size: 13px;
`;

const TermsLink = styled.span`
  font-weight: 500;
  color: ${css.LIGHT_BLUE};

  transition: 0.2;

  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`;

export default (props) => {
  const {
    handleModalClose,
    credentials,
    warnings,
    handleInputChange,
    signupMessage,
    signupSuccess,
    submitSignupForm,
    loading,
    handleKeyDown,
    signupGoogle,
    signupApple,
    openLogin,
    signupFacebook,
    openTerms,
  } = props;

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  preloadImage(scalelupLogo);
  preloadImage(successIcon);
  preloadImage(emailIcon);

  return (
    <ModalWindow
      modal="login"
      handleModalClose={handleModalClose}
      fullScreenHeight={true}
    >
      <Signup>
        {!signupSuccess ? (
          <SignupForm>
            <LogoWrap>
              <img src={scalelupLogo} style={{ width: "100%" }} />
            </LogoWrap>
            <Heading>Create New Account</Heading>
            <Details>
              Registration only takes a moment. Start promoting your business
              today.
            </Details>
            <SocialButtons>
              <AppleLogin
                clientId="com.scalelup.service"
                redirectURI="https://scalelup.com"
                usePopup={true}
                callback={signupApple}
                scope="email name"
                responseMode="query"
                render={(renderProps) => (
                  <SocialButton
                    social="apple"
                    big={mobileScreen}
                    onClick={renderProps.onClick}
                  />
                )}
              />
              <SocialButton
                social="google"
                big={mobileScreen}
                onClick={signupGoogle}
              />
              <FacebookLogin
                appId="1596483107932488"
                onSuccess={(response) => {
                  signupFacebook(response);
                }}
                onFail={(error) => {}}
                render={({ onClick }) => (
                  <SocialButton
                    social="facebook"
                    big={mobileScreen}
                    onClick={onClick}
                  />
                )}
                loginOptions={{
                  auth_type: "rerequest",
                }}
              />
            </SocialButtons>
            <DelimiterWrap>
              <DelimiterLine />
              <DelimiterText>OR</DelimiterText>
              <DelimiterLine />
            </DelimiterWrap>
            <EmailForm>
              <InputWithLabel label="E-Mail Address" width="100%">
                <InputText
                  type="email"
                  name="email"
                  value={credentials.email}
                  placeholder="Enter your email"
                  onChange={handleInputChange}
                  maxLength={100}
                  warning={warnings.includes("email")}
                  onKeyDown={handleKeyDown}
                />
              </InputWithLabel>
              <InputWithLabel
                label="Password"
                width="100%"
                marginTop={css.VERTICAL_BOX_MARGIN}
              >
                <InputText
                  type="password"
                  name="password"
                  value={credentials.password}
                  placeholder="Create password"
                  onChange={handleInputChange}
                  maxLength={100}
                  warning={warnings.includes("password")}
                  onKeyDown={handleKeyDown}
                />
              </InputWithLabel>
              <TermsText>
                By registering, you agree to our{" "}
                <TermsLink onClick={() => openTerms()}>
                  Terms and Conditions
                </TermsLink>
                .
              </TermsText>
              {signupMessage && <ErrorText>{signupMessage}</ErrorText>}
              <EmailFormControls>
                <ButtonPrimary onClick={submitSignupForm} disabled={loading}>
                  Create Account
                </ButtonPrimary>
              </EmailFormControls>
              <SignUpInfo>
                Already have an account?{" "}
                <LoginLink onClick={() => openLogin()}>Log In</LoginLink>
              </SignUpInfo>
            </EmailForm>
          </SignupForm>
        ) : (
          <SignupSuccessInfo>
            <LogoWrap>
              <img src={scalelupLogo} style={{ width: "100%" }} />
            </LogoWrap>
            <Heading>Create New Account</Heading>
            <SuccessIcon>
              <img src={successIcon} width="100%" alt="Icon" />
            </SuccessIcon>
            <SuccessText>
              Congratulations, your account has been successfully created!
            </SuccessText>
            <EmailInfo>
              <EmailInfoTop>
                <EmailInfoIconWrap>
                  <EmailInfoIcon>
                    <img src={emailIcon} width="100%" alt="Icon" />
                  </EmailInfoIcon>
                </EmailInfoIconWrap>
                <EmailInfoText>
                  We have sent the activation link to your email address. Please
                  follow the link in the email to log in to your new account.
                </EmailInfoText>
              </EmailInfoTop>
              <EmailInfoSpamInfo>
                If you cannot find the activation email in your inbox, please
                check your SPAM folder.
              </EmailInfoSpamInfo>
            </EmailInfo>
          </SignupSuccessInfo>
        )}
      </Signup>
    </ModalWindow>
  );
};
