import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import globalConstants from "constants/globalConstants";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";
import WalletBalancesContainer from "./WalletBalancesContainer";
import TransactionsContainer from "./TransactionsContainer";
import SectionTitle from "components/text/SectionTitle";
import MessageBox from "components/other/MessageBox";
import CashTransactionsContainer from "./CashTransactionsContainer";

const Wallet = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Wallet | Scalelup";
  }, []);

  const [searchParams] = useSearchParams();

  const [successBox, setSuccessBox] = useState(false);

  useEffect(() => {
    const success = searchParams.get("success") === "true";

    if (success) setSuccessBox(true);
  }, []);

  const closeMessageBox = () => {
    setSuccessBox(false);
  };

  return (
    <Layout>
      <Wallet>
        <PageTitle>Wallet</PageTitle>
        {successBox && (
          <MessageBox
            title="Payment Received"
            text="We have successfully processed your payment and credited coins to your account."
            closeBox={closeMessageBox}
            margin="0 0 24px 0"
          />
        )}
        <WalletBalancesContainer />
        <SectionTitle>Coins Transactions</SectionTitle>
        <TransactionsContainer />
        <SectionTitle>Cash Transactions</SectionTitle>
        <CashTransactionsContainer />
      </Wallet>
    </Layout>
  );
};
