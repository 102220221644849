import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import { AUTH_ID } from "constants";
import BalanceBox from "./BalanceBox";

export default () => {
  // Fetch data
  const GET_BALANCE_DATA = gql`
    query GetBalanceData {
      balance {
        balance
      }
    }
  `;

  const sn = "s" + localStorage.getItem("sn");

  const BALANCE_UPDATES_SUBSCRIPTION = gql`
    subscription onBalanceUpdate($userId: uuid!) {
      ${sn} {
        users_by_pk(id: $userId) {
          balance
        }
      }
    }
  `;

  const { subscribeToMore, data, error } = useQuery(GET_BALANCE_DATA, {
    fetchPolicy: "network-only",
    pollInterval: 600000,
  });

  if (error) {
    console.log("error fetching balance", error);
    captureException(error);
  }

  const userId = localStorage.getItem(AUTH_ID);

  //console.log("data", data);

  return (
    <BalanceBox
      data={data}
      subscribeToUpdates={() =>
        subscribeToMore({
          document: BALANCE_UPDATES_SUBSCRIPTION,
          variables: { userId },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;

            if (!subscriptionData.data[sn].users_by_pk) {
              return prev;
            }

            return {
              balance: {
                balance: subscriptionData.data[sn].users_by_pk.balance,
                __typename: "User",
              },
            };
          },
        })
      }
    />
  );
};
