import styled from "styled-components";

import * as css from "styles/CssVariables.js";

import chevron from "assets/img/icons/icons8-expand-down.svg";
import chevronGrey from "assets/img/icons/icons8-expand-down-grey.svg";

const Dropdown = styled.div`
  width: 100%;
  height: 42px;
  padding: 0px 20px;
  box-sizing: border-box;

  background: ${(props) => {
    if (props.warning) {
      return "#FF6D88";
    }

    if (props.background) return props.background;
    else return css.DARK_BOX_BACKGROUND;
  }};
  border-radius: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: 0.2s;

  ${(props) =>
    !props.disabled &&
    !props.warning &&
    `&:hover {
    cursor: pointer;
    background: rgba(19, 30, 40, 0.7);
  }`}
`;

const DropdownContent = styled.div`
  width: calc(100% - 24px);
  padding: 0px;

  font-size: 15px;
  font-weight: 300;
  font-family: "Arial", sans-serif;
  color: ${(props) => (props.disabled ? css.TEXT_GREY : "#ffffff")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DropdownArrow = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ onClick, children, disabled, dropdownRef, warning }) => {
  const icon = disabled ? chevronGrey : chevron;

  return (
    <Dropdown
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
      disabled={disabled ? 1 : 0}
      ref={dropdownRef}
      warning={warning ? 1 : 0}
    >
      <DropdownContent disabled={disabled}>{children}</DropdownContent>
      <DropdownArrow>
        <img src={icon} width="100%" alt="Chevron" />
      </DropdownArrow>
    </Dropdown>
  );
};
