import styled from "styled-components";
import { useEffect, useRef } from "react";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import Dropdown from "components/forms/Dropdown";
import RollingBox from "components/other/RollingBox";
import DropdownSelection from "components/forms/DropdownSelection";
import SelectionItem from "components/forms/SelectionItem";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import InputWithLabel from "components/forms/InputWithLabel";
import InputText from "components/forms/InputText";
import Tip from "components/other/Tip";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

import bankIcon from "assets/img/icons/icons8-bank.png";
import bitcoinIcon from "assets/img/icons/icons8-bitcoin.svg";
import skrillIcon from "assets/img/icons/icons8-skrill.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";
import ButtonSecondary from "components/buttons/ButtonSecondary";
import ButtonSecondaryDanger from "components/buttons/ButtonSecondaryDanger";
import ButtonWhite from "components/buttons/ButtonWhite";
import ButtonPrimaryDanger from "components/buttons/ButtonPrimaryDanger";

const DeleteAccount = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  /* min-height: 360px; */

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const TitleText = styled.div`
  margin-top: 6px;
`;

const DeleteButtonWrap = styled.div`
  display: flex;
`;

const ConfirmDeleteForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const ConfirmDeleteFormText = styled.div`
  font-weight: 500;
  color: ${css.NEGATIVE_RED};
`;

const ConfirmDeleteFormButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${css.BUTTONS_GAP};
`;

export default ({
  confirmDeleteForm,
  toggleDeleteAccountForm,
  deleteButtonBlock,
  onDeleteAccount,
}) => {
  return (
    <DeleteAccount>
      <TitleText>
        Here you can delete your account. All data associated with your account
        will be erased. This action cannot be undone.
      </TitleText>
      <RollingBox>
        <DeleteButtonWrap>
          {!confirmDeleteForm && (
            <ButtonSecondaryDanger onClick={toggleDeleteAccountForm}>
              Delete Account
            </ButtonSecondaryDanger>
          )}
          {confirmDeleteForm && (
            <ConfirmDeleteForm>
              <ConfirmDeleteFormText>
                Your Scalelup account will be permanently deleted.
              </ConfirmDeleteFormText>
              <ConfirmDeleteFormButtons>
                <ButtonWhite onClick={toggleDeleteAccountForm}>
                  Cancel
                </ButtonWhite>
                <ButtonPrimaryDanger
                  disabled={deleteButtonBlock}
                  onClick={onDeleteAccount}
                >
                  DELETE
                </ButtonPrimaryDanger>
              </ConfirmDeleteFormButtons>
            </ConfirmDeleteForm>
          )}
        </DeleteButtonWrap>
      </RollingBox>
    </DeleteAccount>
  );
};
