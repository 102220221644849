import styled from "styled-components";
import { useEffect } from "react";

import * as css from "styles/CssVariables.js";
import { numberWithCommas } from "modules/functions";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import Tip from "components/other/Tip";

import coinsIcon from "assets/img/icons/coins-icon.svg";
import dollarIcon from "assets/img/icons/dollar-icon.svg";
import dollarWhiteIcon from "assets/img/icons/dollar-icon-white.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";
import discoverIcon from "assets/img/icons/menu-discover-white.svg";
import referralsIcon from "assets/img/icons/menu-referrals.svg";
import { useNavigate } from "react-router-dom";

const WalletBalances = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }
`;

const LeftColumn = styled.div`
  min-height: ${(props) => (props.haspendingbalance ? "226px" : "206px")};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LeftTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const Balances = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const Balance = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const BalanceIcon = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
`;

const BalanceText = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const CashBalanceWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const PendingBalance = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PendingBalanceText = styled.div`
  font-size: 16px;
  font-weight: 300;
`;

const PendingBalanceIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

const PendingBalanceAmountBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PurchaseButtonWrap = styled.div`
  display: flex;
`;

const RightColumn = styled.div``;

const TipBox = styled.div`
  max-width: 315px;
  padding: ${css.BOX_PADDING_SECONDARY};
  width: 100%;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const TipWrap = styled.div`
  display: flex;
`;

const TipIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const TipText = styled.div`
  padding-left: 12px;

  font-size: 18px;
  font-weight: 500;

  flex: 1;
`;

const TipColumns = styled.div`
  display: flex;
  gap: 6px;
`;

const TipColumn = styled.div`
  max-width: 130px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 0.5;
`;

const TipColumnIcon = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
`;

const TipColumnText = styled.div``;

export default ({ data, subscribeToUpdates }) => {
  const navigate = useNavigate();

  const { balance, cash_balance } = data.walletBalances.user;

  useEffect(() => {
    subscribeToUpdates();
  }, []);

  const onDiscoverClick = () => {
    navigate("/discover");
  };

  const onReferralProgramClick = () => {
    navigate("/referrals");
  };

  let cashBalance = cash_balance;
  let pendingBalance = data.walletBalances.pendingBalance;

  if (cashBalance < 0) {
    pendingBalance = pendingBalance + cashBalance;
    cashBalance = 0;
  }

  return (
    <WalletBalances>
      <LeftColumn
        haspendingbalance={data.walletBalances.pendingBalance > 0 ? 1 : 0}
      >
        <LeftTop>
          <BoxTitle>Your Balance</BoxTitle>
          <Balances>
            <Balance>
              <BalanceIcon>
                <img src={coinsIcon} style={{ width: "100%" }} />
              </BalanceIcon>
              <BalanceText>{numberWithCommas(balance)} Coins</BalanceText>
            </Balance>
            <CashBalanceWrap>
              <Balance>
                <BalanceIcon>
                  <img src={dollarIcon} style={{ width: "100%" }} />
                </BalanceIcon>
                <BalanceText>{Math.round(cashBalance)} USD</BalanceText>
              </Balance>
              {data.walletBalances.pendingBalance > 0 && (
                <PendingBalance>
                  <PendingBalanceText>Pending</PendingBalanceText>
                  <PendingBalanceIcon>
                    <img src={dollarWhiteIcon} style={{ width: "100%" }} />
                  </PendingBalanceIcon>
                  <PendingBalanceAmountBox>
                    <PendingBalanceText>
                      {numberWithCommas(pendingBalance)} USD
                    </PendingBalanceText>
                    <Tip
                      paddingTop="0"
                      tip="Cash bonus earnings and affiliate commissions will be transferred to your cash balance at the end of the month.<br><br>Payouts will be processed at the beginning of next month."
                    />
                  </PendingBalanceAmountBox>
                </PendingBalance>
              )}
            </CashBalanceWrap>
          </Balances>
        </LeftTop>
        <PurchaseButtonWrap>
          <ButtonPrimary onClick={() => navigate("/purchase-coins")}>
            Purchase Coins
          </ButtonPrimary>
        </PurchaseButtonWrap>
      </LeftColumn>
      <RightColumn>
        <TipBox>
          <TipWrap>
            <TipIcon>
              <img src={bulbIcon} alt="Tip" style={{ width: "100%" }} />
            </TipIcon>
            <TipText>How to Earn Free Coins</TipText>
          </TipWrap>
          <TipColumns>
            <TipColumn>
              <TipColumnIcon>
                <img src={discoverIcon} alt="Tip" style={{ width: "100%" }} />
              </TipColumnIcon>
              <TipColumnText>
                Find coins by exploring opportunities
              </TipColumnText>
              <ButtonPrimarySmall onClick={onDiscoverClick}>
                Discover
              </ButtonPrimarySmall>
            </TipColumn>
            <TipColumn>
              <TipColumnIcon>
                <img src={referralsIcon} alt="Tip" style={{ width: "100%" }} />
              </TipColumnIcon>
              <TipColumnText>
                Earn a reward by referring new users
              </TipColumnText>
              <ButtonPrimarySmall onClick={onReferralProgramClick}>
                Referral Program
              </ButtonPrimarySmall>
            </TipColumn>
          </TipColumns>
        </TipBox>
      </RightColumn>
    </WalletBalances>
  );
};
