import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { captureException } from "@sentry/react";

import { addToStateArray, removeFromStateArray } from "modules/functions";

import Posts from "./Posts";

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [switchLoading, setSwitchLoading] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [tutorialBox, setTutorialBox] = useState(false);
  const [page, setPage] = useState(() => {
    const receivedPage = Number(searchParams.get("page"));

    if (receivedPage > 0) return receivedPage;

    return 1;
  });

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      hasViews: user.hasViews,
    })
  );

  const { hasViews } = useSelector(UserProperties);

  // Fetch data
  const limit = 3;

  const GET_POSTS_DATA = gql`
    query GetPostsData($page: Int!, $limit: Int) {
      posts(page: $page, limit: $limit) {
        posts {
          id
          created_at
          name
          status
          on_hold
          switched_on
          text
          image
          image_link
          rejection_reason
          likes
          daily_limit
          posts_stats {
            id
            to
            total_impressions
            total_interactions
            total_spent
          }
          impressions {
            id
            registered_at
            interacted
            cpi
            country
          }
        }
        numOfResults
      }
    }
  `;

  const { loading, data, error, refetch } = useQuery(GET_POSTS_DATA, {
    variables: { page, limit },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching posts error", error);
    captureException(error);
  }

  // handle Post switch
  const TOGGLE_POST_SWITCH = gql`
    mutation TogglePostSwitch($postId: ID!) {
      togglePostSwitch(postId: $postId) {
        id
        status
        switched_on
      }
    }
  `;

  const [togglePostSwitch] = useMutation(TOGGLE_POST_SWITCH);

  const onSwitchClick = async (postId) => {
    try {
      if (!postId) return;

      if (switchLoading.includes(postId)) return;

      addToStateArray(switchLoading, postId, setSwitchLoading);

      await togglePostSwitch({
        variables: {
          postId,
        },
      });

      removeFromStateArray(switchLoading, postId, setSwitchLoading);
    } catch (error) {
      captureException(error);
      return;
    }
  };

  // handle Post edit
  const onEditClick = (postId, page) => {
    let navigateTo = `/modify-post?id=${postId}`;

    if (page) navigateTo += `&page=${page}`;

    navigate(navigateTo);
  };

  // handle Post delete
  const onDeleteClick = (postId) => {
    setConfirmDelete(postId);
  };

  const closeDeleteModal = () => {
    setConfirmDelete(null);
  };

  const DELETE_POST = gql`
    mutation DeletePost($postId: ID!) {
      deletePost(postId: $postId) {
        resultCode
      }
    }
  `;

  const [deletePost] = useMutation(DELETE_POST);

  const onConfirmDelete = async () => {
    try {
      if (!confirmDelete) return;

      if (loadingDelete) return;

      setLoadingDelete(true);

      await deletePost({
        variables: {
          postId: confirmDelete,
        },
      });

      refetch();

      setLoadingDelete(false);
      setConfirmDelete(null);
    } catch (error) {
      captureException(error);
      setLoadingDelete(false);
      return;
    }
  };

  // handle Post duplicate
  const onDuplicateClick = (postId) => {
    navigate("/modify-post?id=" + postId + "&duplicate=true");
  };

  // handle Tutorial box
  const closeBox = () => {
    setTutorialBox(false);

    localStorage.setItem("closed-discover-t-box", "y");
  };

  useEffect(() => {
    const closedBox = localStorage.getItem("closed-discover-t-box");

    if (!hasViews && !closedBox) setTutorialBox(true);
    else setTutorialBox(false);
  }, [hasViews]);

  console.log({ data });

  // handle pagination
  if (data) {
    if (data.posts.numOfResults > 0)
      if (page > Math.ceil(data.posts.numOfResults / limit)) setPage(1);
  }

  const paginate = (where) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (where === "previous") setPage((prevState) => prevState - 1);
    if (where === "next") setPage((prevState) => prevState + 1);
  };

  console.log({ page });

  return (
    <Posts
      loading={loading}
      data={data}
      onSwitchClick={onSwitchClick}
      switchLoading={switchLoading}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      confirmDelete={confirmDelete}
      closeDeleteModal={closeDeleteModal}
      onConfirmDelete={onConfirmDelete}
      loadingDelete={loadingDelete}
      onDuplicateClick={onDuplicateClick}
      tutorialBox={tutorialBox}
      closeBox={closeBox}
      limit={limit}
      paginate={paginate}
      page={page}
    />
  );
};
