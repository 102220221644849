import styled from "styled-components";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { isEmail } from "validator";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { captureException } from "@sentry/react";

import {
  AUTH_ID,
  AUTH_TOKEN,
  AUTH_WS_TOKEN,
  REF_CODE,
  SHARD_NUMBER,
} from "constants";
import globalConstants from "constants/globalConstants";
import { validatePasswordString } from "modules/functions";
import Signup from "./Signup";

// Actions
import { openModal } from "store/actions";
import { PROMO_CODE } from "constants";

const SignupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

export default ({ handleModalClose, openErrorWindow, ipAddress }) => {
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [signupMessage, setSignupMessage] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [loadingSignupGoogle, setLoadingSignupGoogle] = useState(false);
  const [loadingSignupApple, setLoadingSignupApple] = useState(false);
  const [loadingSignupFacebook, setLoadingSignupFacebook] = useState(false);

  const handleInputChange = (e) => {
    if (loading) {
      return;
    }

    const { target } = e;

    let newCredentials = { ...credentials };

    newCredentials[target.name] = target.value;

    setCredentials(newCredentials);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitSignupForm();
    }
  };

  const openLogin = () => {
    dispatch(openModal("login"));
  };

  const openTerms = () => {
    window.open(`https://scalelup.com/terms`, "_blank");
  };

  // handle Form submit
  const SIGNUP = gql`
    mutation Signup(
      $email: String!
      $password: String!
      $ipAddress: String
      $utm: UTM!
      $sponsorCode: String
      $promo: String
    ) {
      signUp(
        email: $email
        password: $password
        ipAddress: $ipAddress
        utm: $utm
        sponsorCode: $sponsorCode
        promo: $promo
      ) {
        resultCode
      }
    }
  `;

  const [signUp] = useMutation(SIGNUP);

  const submitSignupForm = async () => {
    try {
      if (loading || loadingSignupGoogle || loadingSignupApple) return;

      setLoading(true);
      setWarnings([]);
      setSignupMessage(null);

      const { email, password } = credentials;

      // validate inputs
      let newWarnings = [];

      if (
        email.trim().length < 5 ||
        email.trim().length > 100 ||
        !isEmail(email)
      ) {
        newWarnings.push("email");
      }

      if (
        password.trim().length > 100 ||
        !validatePasswordString(password.trim())
      ) {
        newWarnings.push("password");
        setSignupMessage(
          "The password must contain at least 8 characters, one lowercase letter, one uppercase letter, and a digit."
        );
      }

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        setLoading(false);
        return;
      }

      // prepare UTM data
      let utm = {};

      if (localStorage.getItem("utmSource"))
        utm.utmSource = localStorage.getItem("utmSource");

      if (localStorage.getItem("utmMedium"))
        utm.utmMedium = localStorage.getItem("utmMedium");

      if (localStorage.getItem("utmCampaign"))
        utm.utmCampaign = localStorage.getItem("utmCampaign");

      if (localStorage.getItem("utmBanner"))
        utm.utmBanner = localStorage.getItem("utmBanner");

      // prepare Sponsor code
      let sponsorCode = null;

      if (localStorage.getItem(REF_CODE))
        sponsorCode = localStorage.getItem(REF_CODE);

      // prepare Promo code
      let promoCode = null;

      if (localStorage.getItem(PROMO_CODE))
        promoCode = localStorage.getItem(PROMO_CODE);

      const result = await signUp({
        variables: {
          email: email.trim(),
          password: password.trim(),
          ipAddress,
          utm,
          sponsorCode,
          promo: promoCode,
        },
      });

      const { resultCode } = result.data.signUp;

      console.log({ resultCode });

      if (resultCode === 5 || resultCode === 6) {
        // email already registered
        setSignupMessage(
          "This email address is already registered. Please log in to your account."
        );
        setLoading(false);
        return;
      }

      if (resultCode === 7) {
        // email address not eligible
        setSignupMessage(
          "This email address cannot be used to create an account. Please use a different address."
        );
        setWarnings(["email"]);
        setLoading(false);
        return;
      }

      if (resultCode === 9 || resultCode === 10 || resultCode === 12) {
        // sign up blocked
        setSignupMessage(
          "We're sorry, we are currently unable to create an account for you."
        );
        setLoading(false);
        return;
      }

      if (resultCode === 13) {
        // country rate limited
        setSignupMessage(
          "We apologize, but due to high demand, registrations are currently paused to enhance the system. Please try again in a few hours."
        );
        setLoading(false);
        return;
      }

      if (resultCode !== 0) {
        // signup failed
        setLoading(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // sign up successfull
      setSignupSuccess(true);
      setLoading(false);
    } catch (error) {
      captureException(error);
      setLoading(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Google Signup
  const GOOGLE_LOGIN = gql`
    mutation GoogleLogin(
      $accessToken: String!
      $utm: UTM!
      $ipAddress: String
      $sponsorCode: String
      $promo: String
    ) {
      googleLogin(
        accessToken: $accessToken
        utm: $utm
        ipAddress: $ipAddress
        sponsorCode: $sponsorCode
        promo: $promo
      ) {
        resultCode
        token
        wsToken
        id
        shardNumber
      }
    }
  `;

  const [googleLogin] = useMutation(GOOGLE_LOGIN);

  const signupGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        if (
          loading ||
          loadingSignupGoogle ||
          loadingSignupApple ||
          loadingSignupFacebook
        )
          return;

        setLoadingSignupGoogle(true);

        // prepare UTM data
        let utm = {};

        if (localStorage.getItem("utmSource"))
          utm.utmSource = localStorage.getItem("utmSource");

        if (localStorage.getItem("utmMedium"))
          utm.utmMedium = localStorage.getItem("utmMedium");

        if (localStorage.getItem("utmCampaign"))
          utm.utmCampaign = localStorage.getItem("utmCampaign");

        if (localStorage.getItem("utmBanner"))
          utm.utmBanner = localStorage.getItem("utmBanner");

        // prepare Sponsor code
        let sponsorCode = null;

        if (localStorage.getItem(REF_CODE))
          sponsorCode = localStorage.getItem(REF_CODE);

        // prepare Promo code
        let promoCode = null;

        if (localStorage.getItem(PROMO_CODE))
          promoCode = localStorage.getItem(PROMO_CODE);

        const result = await googleLogin({
          variables: {
            accessToken: codeResponse.access_token,
            utm,
            ipAddress,
            sponsorCode,
            promo: promoCode,
          },
        });

        const { resultCode, id, token, wsToken, shardNumber } =
          result.data.googleLogin;

        if (resultCode === 2) {
          // user already logged, delete tokens for fresh login
          localStorage.removeItem(AUTH_ID);
          localStorage.removeItem(AUTH_TOKEN);
          localStorage.removeItem(AUTH_WS_TOKEN);
          localStorage.removeItem(SHARD_NUMBER);
          window.location = "/";
          return;
        }

        if (resultCode === 7 || resultCode === 9 || resultCode === 11) {
          // sign up blocked
          setSignupMessage(
            "We're sorry, we are currently unable to create an account for you."
          );
          setLoadingSignupGoogle(false);
          return;
        }

        if (resultCode === 10) {
          // country rate limited
          setSignupMessage(
            "We apologize, but due to high demand, registrations are currently paused to enhance the system. Please try again in a few hours."
          );
          setLoadingSignupGoogle(false);
          return;
        }

        if (resultCode !== 0 || !token) {
          // signup failed
          setLoadingSignupGoogle(false);
          handleModalClose();
          openErrorWindow();

          return;
        }

        // signup successfull, redirect to account
        localStorage.setItem(AUTH_ID, id);
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem(AUTH_WS_TOKEN, wsToken);
        localStorage.setItem(SHARD_NUMBER, shardNumber);
        window.location = "/onboarding?signup-success";
      } catch (error) {
        captureException(error);
        setLoadingSignupGoogle(false);
        handleModalClose();
        openErrorWindow();
        return;
      }
    },
    onError: (error) => {
      console.log("signupGoogle error", error);
      captureException(error);
    },
  });

  // handle Apple signup
  const APPLE_LOGIN = gql`
    mutation AppleLogin(
      $code: String!
      $utm: UTM!
      $ipAddress: String
      $sponsorCode: String
      $promo: String
    ) {
      appleLogin(
        code: $code
        utm: $utm
        ipAddress: $ipAddress
        sponsorCode: $sponsorCode
        promo: $promo
      ) {
        resultCode
        token
        wsToken
        id
        shardNumber
      }
    }
  `;

  const [appleLogin] = useMutation(APPLE_LOGIN);

  const signupApple = async (message) => {
    try {
      if (!message.authorization) return;

      if (
        loading ||
        loadingSignupGoogle ||
        loadingSignupApple ||
        loadingSignupFacebook
      )
        return;

      setLoadingSignupApple(true);

      // prepare UTM data
      let utm = {};

      if (localStorage.getItem("utmSource"))
        utm.utmSource = localStorage.getItem("utmSource");

      if (localStorage.getItem("utmMedium"))
        utm.utmMedium = localStorage.getItem("utmMedium");

      if (localStorage.getItem("utmCampaign"))
        utm.utmCampaign = localStorage.getItem("utmCampaign");

      if (localStorage.getItem("utmBanner"))
        utm.utmBanner = localStorage.getItem("utmBanner");

      // prepare Sponsor code
      let sponsorCode = null;

      if (localStorage.getItem("ref"))
        sponsorCode = localStorage.getItem("ref");

      // prepare Promo code
      let promoCode = null;

      if (localStorage.getItem(PROMO_CODE))
        promoCode = localStorage.getItem(PROMO_CODE);

      const result = await appleLogin({
        variables: {
          code: message.authorization.code,
          utm,
          ipAddress,
          sponsorCode,
          promo: promoCode,
        },
      });

      const { resultCode, token, wsToken, id, shardNumber } =
        result.data.appleLogin;

      if (resultCode === 2) {
        // user already logged, delete tokens for fresh login
        localStorage.removeItem(AUTH_ID);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_WS_TOKEN);
        localStorage.removeItem(SHARD_NUMBER);
        window.location = "/";
        return;
      }

      if (resultCode === 7 || resultCode === 9) {
        // sign up blocked
        setSignupMessage(
          "We're sorry, we are currently unable to create an account for you."
        );
        setLoadingSignupApple(false);
        return;
      }

      if (resultCode !== 0 || !token) {
        // signup failed
        setLoadingSignupApple(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // signup successfull, redirect to account
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/onboarding?signup-success";
    } catch (error) {
      captureException(error);
      setLoadingSignupApple(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Facebook signup
  const FACEBOOK_LOGIN = gql`
    mutation FacebookLogin(
      $accessToken: String!
      $utm: UTM!
      $ipAddress: String
      $sponsorCode: String
      $promo: String
    ) {
      facebookLogin(
        accessToken: $accessToken
        utm: $utm
        ipAddress: $ipAddress
        sponsorCode: $sponsorCode
        promo: $promo
      ) {
        resultCode
        token
        wsToken
        id
        shardNumber
      }
    }
  `;

  const [facebookLogin] = useMutation(FACEBOOK_LOGIN);

  const signupFacebook = async (response) => {
    try {
      if (!response.accessToken) return;

      if (
        loading ||
        loadingSignupGoogle ||
        loadingSignupApple ||
        loadingSignupFacebook
      )
        return;

      setLoadingSignupFacebook(true);

      // prepare UTM data
      let utm = {};

      if (localStorage.getItem("utmSource"))
        utm.utmSource = localStorage.getItem("utmSource");

      if (localStorage.getItem("utmMedium"))
        utm.utmMedium = localStorage.getItem("utmMedium");

      if (localStorage.getItem("utmCampaign"))
        utm.utmCampaign = localStorage.getItem("utmCampaign");

      if (localStorage.getItem("utmBanner"))
        utm.utmBanner = localStorage.getItem("utmBanner");

      // prepare Sponsor code
      let sponsorCode = null;

      if (localStorage.getItem("ref"))
        sponsorCode = localStorage.getItem("ref");

      // prepare Promo code
      let promoCode = null;

      if (localStorage.getItem(PROMO_CODE))
        promoCode = localStorage.getItem(PROMO_CODE);

      const result = await facebookLogin({
        variables: {
          accessToken: response.accessToken,
          utm,
          ipAddress,
          sponsorCode,
          promo: promoCode,
        },
      });

      const { resultCode, token, wsToken, id, shardNumber } =
        result.data.facebookLogin;

      if (resultCode === 2) {
        // user already logged, delete tokens for fresh login
        setLoadingSignupFacebook(false);
        handleModalClose();
        openErrorWindow();

        localStorage.removeItem(AUTH_ID);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_WS_TOKEN);
        localStorage.removeItem(SHARD_NUMBER);
        return;
      }

      if (resultCode === 8 || resultCode === 10) {
        // sign up blocked
        setSignupMessage(
          "We're sorry, we are currently unable to create an account for you."
        );
        setLoadingSignupFacebook(false);
        return;
      }

      if (resultCode === 11) {
        // email permission missing
        setSignupMessage(
          "The registration could not be completed because we did not receive permission to obtain your email address from Facebook. Please try again and ensure that the permission to request your email address is checked in the Facebook dialog box."
        );
        setLoadingSignupFacebook(false);
        return;
      }

      if (resultCode !== 0 || !token) {
        // login failed
        setLoadingSignupFacebook(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // login successfull, redirect to account
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/onboarding?signup-success";
    } catch (error) {
      captureException(error);
      setLoadingSignupFacebook(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  return (
    <SignupContainer>
      <Signup
        handleModalClose={handleModalClose}
        handleInputChange={handleInputChange}
        credentials={credentials}
        submitSignupForm={submitSignupForm}
        loading={loading}
        warnings={warnings}
        signupMessage={signupMessage}
        openModal={openModal}
        handleKeyDown={handleKeyDown}
        signupSuccess={signupSuccess}
        signupGoogle={signupGoogle}
        signupApple={signupApple}
        openLogin={openLogin}
        signupFacebook={signupFacebook}
        openTerms={openTerms}
      />
    </SignupContainer>
  );
};
