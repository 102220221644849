import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import CashTransactionList from "./CashTransactionList";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  const [page, setPage] = useState(1);

  // Fetch data
  const GET_CASH_TRANSACTION_LIST = gql`
    query GetCashTransactionList($page: Int!) {
      cashTransactionList(page: $page) {
        transactions {
          id
          created_at
          type
          amount
          data
        }
        numOfResults
      }
    }
  `;

  const { data, error } = useQuery(GET_CASH_TRANSACTION_LIST, {
    variables: {
      page,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching cash transaction list error", error);
    captureException(error);
  }

  console.log({ data });

  const limit = 10;

  if (data) {
    if (data.cashTransactionList.numOfResults > 0)
      if (page > Math.ceil(data.cashTransactionList.numOfResults / limit))
        setPage(1);
  }

  const paginate = (where) => {
    if (where === "previous") setPage((prevState) => prevState - 1);
    if (where === "next") setPage((prevState) => prevState + 1);
  };

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="435px" />
        </LoadingBox>
      )}
      {data && (
        <CashTransactionList
          data={data}
          page={page}
          limit={limit}
          paginate={paginate}
        />
      )}
    </>
  );
};
