import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { isURL } from "validator";
import { useSearchParams, useNavigate, useBlocker } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { captureException } from "@sentry/react";

import { BACKEND_SERVER_URL, BASE_CPI } from "constants";
import { POST_TEMPLATES } from "constants/postTemplates";

import {
  getCountriesByPLevels,
  getCpiEstimate,
  getRandomArrayItem,
  removeExtraEmptyLines,
} from "modules/functions";

// Actions
import { closeHidingMenu, updateProgressPosts } from "store/actions";

import CreatePost from "./CreatePost";

export default () => {
  const pickerRef = useRef();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("id") ? searchParams.get("id") : null;
  const duplicate = searchParams.get("duplicate") === "true" ? true : false;
  const fromPage = searchParams.get("page");

  const [allCountries, setAllCountries] = useState([]);
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [imageWarning, setImageWarning] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [name, setName] = useState("");
  const [pickerOpened, setPickerOpened] = useState(false);
  const [targetingOpened, setTargetingOpened] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cpiEstimate, setCpiEstimate] = useState({});
  const [cpiFormOpened, setCpiFormOpened] = useState(false);
  const [cpi, setCpi] = useState("");
  const [warnings, setWarnings] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [textEdited, setTextEdited] = useState(false);
  const [confirmTemplate, setConfirmTemplate] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [tutorialBox, setTutorialBox] = useState(false);
  const [viewIntervalSelectionOpened, setViewIntervalSelectionOpened] =
    useState(false);
  const [viewInterval, setViewInterval] = useState(1);
  const [run, setRun] = useState(true);
  const [limit, setLimit] = useState("");
  const [placeholdersInText, setPlaceholdersInText] = useState([]);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      hasPost: user.hasPost,
    })
  );

  const { hasPost } = useSelector(UserProperties);

  const onTextInputChange = (e) => {
    setText(e.target.value);
    if (!textEdited) setTextEdited(true);
    if (!formEdited) setFormEdited(true);
  };

  const onSmileyClick = () => {
    setPickerOpened(!pickerOpened);
  };

  const onEmojiClick = (emoji) => {
    setText(text + emoji.emoji);

    if (!textEdited) setTextEdited(true);
    if (!formEdited) setFormEdited(true);
  };

  function usePickerOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPickerOpened(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // handle post image cloud upload
  const axiosApi = axios.create({
    baseURL: BACKEND_SERVER_URL,
  });

  async function postFile(url, data, config = {}) {
    return axiosApi
      .post(url, data, { ...config })
      .then((response) => response.data);
  }

  const onImageInputChange = async (e) => {
    try {
      if (e.target.files[0]) {
        const maxFileSize = 6000;

        if (e.target.files[0].size > maxFileSize * 1000) {
          setImageWarning("tooLargeFile");
          return;
        }

        setImage(null);
        setImageWarning(null);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const result = await postFile("/upload/post-image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (result.error) {
          console.log("File upload failed!");
          return;
        }

        if (result.fileName) {
          setImage(result.fileName);
          if (!formEdited) setFormEdited(true);
        }
      }
    } catch (error) {
      captureException(error);
      console.log("onImageInputChange error", error);
    }
  };

  const removeImage = () => {
    setImage(null);
    if (!formEdited) setFormEdited(true);
  };

  const openTargeting = () => {
    setTargetingOpened(true);
  };

  const closeTargeting = () => {
    setTargetingOpened(false);
  };

  // handle Image link input
  const onImageLinkInputChange = (e) => {
    setImageLink(e.target.value);
    if (!formEdited) setFormEdited(true);
  };

  // handle Name input
  const onNameInputChange = (e) => {
    setName(e.target.value);
    if (!formEdited) setFormEdited(true);
  };

  // get User profile data
  const GET_USER_PROFILE_DATA = gql`
    query UserProfileData {
      userProfileData {
        id
        username
        profile_image
      }
    }
  `;

  const { loading: loadingUserProfile, data: userProfileData } = useQuery(
    GET_USER_PROFILE_DATA
  );

  // load Vountries data
  const GET_COUNTRIES = gql`
    query CountriesData {
      countriesData {
        id
        country
        cpi
      }
    }
  `;

  const { loading: loadingCountries, data: countriesData } = useQuery(
    GET_COUNTRIES,
    {
      fetchPolicy: "network-only",
    }
  );

  // save all countries to state
  useEffect(() => {
    if (!loadingCountries && countriesData) {
      const countries = countriesData.countriesData.map(
        (country) => country.country
      );

      setAllCountries(countries);
    }
  }, [loadingCountries]);

  // get existing post data if editing
  const GET_POST_DATA = gql`
    query GetPostData($postId: ID!) {
      postData(postId: $postId) {
        id
        text
        image
        image_link
        targeting
        max_cpi
        name
        rejection_reason
        daily_limit
      }
    }
  `;

  const {
    loading: loadingPost,
    data: receivedPostData,
    error,
  } = useQuery(GET_POST_DATA, {
    variables: { postId },
    skip: !postId,
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error", error);
    navigate("/modify-post");
  }

  useEffect(() => {
    if (!loadingPost && receivedPostData) {
      if (!receivedPostData.postData) {
        // post id not found or invalid
        navigate("/modify-post");
        return;
      }

      // populate state with existing post data
      const { postData } = receivedPostData;

      setText(postData.text);
      setImage(postData.image);
      setImageLink(postData.image_link ? postData.image_link : "");
      setCountries(postData.targeting);
      setCpi(postData.max_cpi !== 10000 ? postData.max_cpi : "");
      setName(postData.name);
      setLimit(postData.daily_limit ? postData.daily_limit : "");

      setTextEdited(false);
      setFormEdited(false);
    }
  }, [loadingPost]);

  // empty state values when navigated here with no postId
  useEffect(() => {
    if (!postId) {
      setText("");
      setImage(null);
      setImageLink("");
      setCountries(allCountries);
      setCpi("");
      setName("");
      setLimit("");

      setTextEdited(false);
      setFormEdited(false);
    }
  }, [postId]);

  // set all countries as initial if creating new post
  useEffect(() => {
    if (!postId) {
      setCountries(allCountries);
    }
  }, [allCountries]);

  // update countries on change
  const updateCountries = (countries) => {
    setCountries(countries);
    if (!formEdited) setFormEdited(true);
  };

  // update CPI data on countries change
  useEffect(() => {
    if (countriesData) {
      const selectedCountriesData = countriesData.countriesData.filter(
        (country) => countries.includes(country.country)
      );

      const countriesByPLevels = getCountriesByPLevels(selectedCountriesData);

      const cpiEstimate = getCpiEstimate(countriesByPLevels, "l3");

      setCpiEstimate(cpiEstimate);
    }
  }, [countries, countriesData]);

  // handle CPI form
  const openCpiForm = () => {
    setCpiFormOpened(true);
  };

  const closeCpiForm = () => {
    setCpiFormOpened(false);
  };

  const onCpiInputChange = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      let newValue = e.target.value !== "0" ? e.target.value : "";

      setCpi(newValue);
      if (!formEdited) setFormEdited(true);
    }
  };

  // handle post submit
  const SUBMIT_POST = gql`
    mutation SubmitPost(
      $postId: ID
      $text: String!
      $image: String
      $imageLink: String
      $countries: [String!]!
      $cpi: Int
      $name: String!
      $run: Boolean!
      $limit: Int
    ) {
      submitPost(
        postId: $postId
        text: $text
        image: $image
        imageLink: $imageLink
        countries: $countries
        cpi: $cpi
        name: $name
        run: $run
        limit: $limit
      ) {
        resultCode
      }
    }
  `;

  const [submitPost] = useMutation(SUBMIT_POST);

  const onPostSubmit = async () => {
    try {
      setSubmitError(null);

      if (submitLoading) return;

      let newWarnings = [];

      if (text.trim().length < 20) newWarnings.push("text");

      const placeholders = [
        "[Opportunity Name]",
        "[Link to more info]",
        "[Product Name]",
        "[product category]",
        "[Product/Service Name]",
        "[Feature/Benefit]",
        "[Benefit/Advantage]",
        "[Highlight Feature/Benefit]",
      ];

      let newPlaceholdersInText = [];

      for (const placeholder of placeholders) {
        if (text.includes(placeholder)) {
          newPlaceholdersInText.push(placeholder);
        }
      }

      if (newPlaceholdersInText.length > 0) {
        setPlaceholdersInText(newPlaceholdersInText);
        newWarnings.push("placeholdersInText");
      }

      if (
        imageLink.length > 0 &&
        !isURL(imageLink, {
          protocols: ["http", "https"],
          require_valid_protocol: true,
          require_protocol: true,
        })
      )
        newWarnings.push("imageLink");
      if (imageLink.length > 0 && !image) newWarnings.push("imageLinkNoImage");
      if (countries.length === 0) newWarnings.push("targeting");
      if (limit !== "" && limit < 100) newWarnings.push("limit");
      if (cpi !== "" && cpi < BASE_CPI) newWarnings.push("cpi");

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setWarnings([]);
      setPlaceholdersInText([]);
      setSubmitLoading(true);
      setFormEdited(false);

      const submitPostId = duplicate ? null : postId;

      const result = await submitPost({
        variables: {
          postId: submitPostId,
          text: removeExtraEmptyLines(text.trim()),
          image,
          imageLink,
          countries,
          cpi: Number(cpi),
          name,
          run,
          limit: Number(limit),
        },
      });

      setSubmitLoading(false);

      if (result.data.submitPost.resultCode !== 0) {
        const errorText = submitPostId
          ? "Failed to edit the post. Please try again later or contact support."
          : "Failed to create the post. Please try again later or contact support.";

        setSubmitError(errorText);

        return;
      }

      if (!hasPost) dispatch(updateProgressPosts(true));

      navigate("/posts");
    } catch (error) {
      captureException(error);
      console.log("Submit post error", error);
      setSubmitLoading(false);
      setSubmitError(
        "Failed to create or edit the post. Please try again later or contact support."
      );
    }
  };

  // handle Post template generation
  const addTemplateText = () => {
    const randomTemplate = getRandomArrayItem(POST_TEMPLATES);

    setText(randomTemplate);
    setTextEdited(false);
    if (!formEdited) setFormEdited(true);

    if (confirmTemplate) setConfirmTemplate(false);
  };

  const getPostTemplate = () => {
    if (textEdited && text.length !== 0) setConfirmTemplate(true);
    else addTemplateText();
  };

  const closeConfirmModal = () => {
    setConfirmTemplate(false);
  };

  // handle Form leave blocker
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formEdited && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      dispatch(closeHidingMenu());
    }
  }, [blocker.state]);

  // handle Tutorial box
  const closeBox = () => {
    setTutorialBox(false);

    localStorage.setItem("closed-create-post-t-box", "y");
  };

  useEffect(() => {
    const closedBox = localStorage.getItem("closed-create-post-t-box");

    if (!hasPost && !closedBox) setTutorialBox(true);
    else setTutorialBox(false);
  }, [hasPost]);

  // handle View Interval selection
  const toggleViewIntervalSelection = () => {
    return; // disable selection
    setViewIntervalSelectionOpened((prevState) => !prevState);
  };

  const onViewIntervalSelect = (interval) => {
    setViewInterval(interval);
    setViewIntervalSelectionOpened(false);
  };

  // handle Run after validation selection
  const onRunToggleClick = () => {
    setRun((prevState) => !prevState);
  };

  // handle limit settings
  const onLimitInputChange = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      let newValue = e.target.value !== "0" ? e.target.value : "";

      setLimit(newValue);
      if (!formEdited) setFormEdited(true);
    }
  };

  return (
    <CreatePost
      form={postId && !duplicate ? "edit" : "create"}
      text={text}
      onTextInputChange={onTextInputChange}
      onSmileyClick={onSmileyClick}
      pickerOpened={pickerOpened}
      onEmojiClick={onEmojiClick}
      pickerRef={pickerRef}
      usePickerOutsideClick={usePickerOutsideClick}
      onImageInputChange={onImageInputChange}
      image={image}
      onImageLinkInputChange={onImageLinkInputChange}
      imageLink={imageLink}
      onNameInputChange={onNameInputChange}
      name={name}
      warnings={warnings}
      openTargeting={openTargeting}
      closeTargeting={closeTargeting}
      targetingOpened={targetingOpened}
      loading={loadingCountries || loadingUserProfile}
      allCountries={allCountries || []}
      countries={countries}
      updateCountries={updateCountries}
      cpiEstimate={cpiEstimate}
      openCpiForm={openCpiForm}
      closeCpiForm={closeCpiForm}
      cpiFormOpened={cpiFormOpened}
      cpi={cpi}
      onCpiInputChange={onCpiInputChange}
      removeImage={removeImage}
      imageWarning={imageWarning}
      onPostSubmit={onPostSubmit}
      submitLoading={submitLoading}
      submitError={submitError}
      userProfileData={userProfileData?.userProfileData}
      getPostTemplate={getPostTemplate}
      confirmTemplate={confirmTemplate}
      closeConfirmModal={closeConfirmModal}
      addTemplateText={addTemplateText}
      blockModal={blocker.state === "blocked"}
      closeBlockModal={() => blocker.reset()}
      confirmLeaveForm={() => blocker.proceed()}
      tutorialBox={tutorialBox}
      closeBox={closeBox}
      viewIntervalSelectionOpened={viewIntervalSelectionOpened}
      toggleViewIntervalSelection={toggleViewIntervalSelection}
      viewInterval={viewInterval}
      onViewIntervalSelect={onViewIntervalSelect}
      rejectionReason={
        receivedPostData && !duplicate
          ? receivedPostData.postData.rejection_reason
          : null
      }
      run={run}
      onRunToggleClick={onRunToggleClick}
      limit={limit}
      onLimitInputChange={onLimitInputChange}
      placeholdersInText={placeholdersInText}
      fromPage={fromPage}
    />
  );
};
