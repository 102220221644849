import { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { captureException } from "@sentry/react";

import AffiliateProgram from "./AffiliateProgram";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [unlockError, setUnlockError] = useState(null);

  // Fetch data
  const GET_AFFILIATE_PROGRAM_DATA = gql`
    query GetAffiliateProgram {
      affiliateProgramData {
        affiliate
        qualifiedReferrals
        directReferrals
        indirectReferrals
        totalCommissions
        thisMonthDirectRevenue
        directRate
        thisMonthIndirectRevenue
        indirectRate
        thisMonthCommissions
        lastMonthEarnings {
          direct_revenue
          direct_rate
          indirect_revenue
          indirect_rate
          total_commissions
        }
        earningsHistory {
          month
          total_commissions
        }
      }
    }
  `;

  const { data, error, refetch } = useQuery(GET_AFFILIATE_PROGRAM_DATA, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    console.log("fetching affiliate program data error", error);
    captureException(error);
  }

  // handle Unlock Affiliate accounts
  const UNLOCK_AFFILIATE_ACCOUNT = gql`
    mutation UnlockAffiliateAccount {
      unlockAffiliateAccount {
        resultCode
      }
    }
  `;

  const [unlockAffiliateAccount] = useMutation(UNLOCK_AFFILIATE_ACCOUNT);

  const unlockAffiliate = async () => {
    try {
      if (loadingUnlock) return;

      setUnlockError(false);
      setLoadingUnlock(true);

      const result = await unlockAffiliateAccount();

      const { resultCode } = result.data.unlockAffiliateAccount;

      if (resultCode !== 0) {
        setUnlockError(
          "There was a problem processing the request. Please try again or contact support."
        );
        setLoadingUnlock(false);
        return;
      }

      refetch();

      setLoadingUnlock(false);
    } catch (error) {
      captureException(error);
      console.log("Error unlocking affilaite account", error);
    }
  };

  //console.log({ data });

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="230px" />
        </LoadingBox>
      )}
      {data && (
        <AffiliateProgram
          data={data}
          unlockAffiliate={unlockAffiliate}
          loadingUnlock={loadingUnlock}
          unlockError={unlockError}
        />
      )}
    </>
  );
};
