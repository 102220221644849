import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import {
  dateFormatted,
  getTimeAMPM,
  numberWithCommas,
} from "modules/functions";
import { COUNTRY_ICONS } from "constants/countries";
import { Tooltip } from "react-tooltip";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import blockIcon from "assets/img/icons/icons8-unavailable.svg";
import circleGreyIcon from "assets/img/icons/icons8-circle-grey.svg";

const TransactionListBox = styled.div`
  width: 100%;
  min-height: 280px;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

const TableWrap = styled.div`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: -5px;

  overflow-x: auto;
`;

const ListTable = styled.table`
  width: 100%;
  min-width: 560px;
  max-width: 700px;
`;

const TableHead = styled.thead``;

const Label = styled.th`
  padding-bottom: 12px;
  max-width: ${(props) => (props.width ? props.width : "auto")};

  font-size: 18px;
  font-weight: 400;
  text-align: left;
`;

const TableBody = styled.tbody``;

const Cell = styled.td``;

const AmountBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Badge = styled.div`
  height: 22px;
  margin: 4px;
  padding: 0px 8px;

  font-size: 13px;

  border-radius: 11px;
  background: ${(props) =>
    props.green ? css.POSITIVE_GREEN : css.NEGATIVE_RED};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailsWrap = styled.div`
  width: 100%;
  max-width: 210px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Pagination = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
  justify-content: center;
  gap: ${css.BUTTONS_GAP_SMALL};
`;

const NoResults = styled.div`
  width: 100%;
  margin-top: 75px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const NoResultsIcon = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const TypeImpression = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TypeImpressionText = styled.div``;

const CountryIcon = styled.div`
  padding-top: 1px;

  width: 18px;
  min-width: 18px;
  height: 18px;

  &:hover {
    cursor: help;
  }
`;

const NoResultsText = styled.div``;

export default ({ data, page, limit, paginate }) => {
  const { transactions, numOfResults } = data.transactionList;

  const list = transactions.map((transaction) => {
    const { id, created_at, type, data, amount } = transaction;

    let typeText;
    let details;

    switch (type) {
      case "impression":
        const icon = COUNTRY_ICONS[data.country]
          ? COUNTRY_ICONS[data.country].icon
          : null;

        typeText = (
          <TypeImpression>
            {icon && (
              <>
                <CountryIcon
                  data-tooltip-id="countryTip"
                  data-tooltip-html={data.country}
                  data-tooltip-place="top"
                  data-tooltip-delay-show={500}
                  onClick={(e) => e.stopPropagation()}
                >
                  <img src={icon} width="100%" alt={data.country} />
                </CountryIcon>
                <Tooltip id="countryTip" className="tooltip" />
              </>
            )}
            <TypeImpressionText>Impression</TypeImpressionText>
          </TypeImpression>
        );
        details = data.postName;
        break;
      case "rewardsPayout":
        typeText = "Coins found";
        break;
      case "referralReward":
        typeText = "Referral reward";
        details = data.referral_username;
        break;
      case "purchase":
        typeText = "Purchase";
        details = numberWithCommas(amount) + " Coins Package";
        break;
      case "cashCoinsConversion":
        typeText = "Cash conversion";
        details = data.cash + " USD";
        break;
      case "promoBonus":
        typeText = "Bonus";
        details = "";
        break;
      case "bonus":
        typeText = "Bonus";
        details = "";
        break;
    }

    return (
      <tr key={id}>
        <Cell>
          {dateFormatted(new Date(created_at), false) +
            ", " +
            getTimeAMPM(new Date(created_at))}
        </Cell>
        <Cell>{typeText}</Cell>
        <Cell>
          <AmountBox>
            {/* <CoinsIcon>
              <img src={coinsIcon} style={{ width: "100%" }} />
            </CoinsIcon> */}
            <Badge green={amount >= 0 ? 1 : 0}>
              {numberWithCommas(amount)}
            </Badge>
          </AmountBox>
        </Cell>
        <Cell>
          <DetailsWrap>{details}</DetailsWrap>
        </Cell>
      </tr>
    );
  });

  let showPagination = false;

  if (numOfResults > limit) showPagination = true;

  const maxPage = Math.ceil(numOfResults / limit);

  return (
    <TransactionListBox>
      <TableWrap className="table-wrap">
        {list.length > 0 && (
          <ListTable>
            <TableHead>
              <tr>
                <Label width="150px">Date</Label>
                <Label width="130px">Type</Label>
                <Label width="90px">Amount</Label>
                <Label>Details</Label>
              </tr>
            </TableHead>
            <TableBody>{list}</TableBody>
          </ListTable>
        )}
        {list.length === 0 && (
          <NoResults>
            <NoResultsIcon>
              <img src={blockIcon} style={{ width: "100%" }} />
            </NoResultsIcon>
            <NoResultsText>You don't have any transactions yet.</NoResultsText>
          </NoResults>
        )}
      </TableWrap>
      {showPagination && (
        <Pagination>
          {page > 1 && (
            <ButtonPrimarySmall onClick={() => paginate("previous")}>
              Previous
            </ButtonPrimarySmall>
          )}
          {page < maxPage && (
            <ButtonPrimarySmall onClick={() => paginate("next")}>
              Next
            </ButtonPrimarySmall>
          )}
        </Pagination>
      )}
    </TransactionListBox>
  );
};
