import { useEffect, useState } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import PurchaseCoins from "./PurchaseCoins";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [warnings, setWarnings] = useState([]);
  const [billing, setBilling] = useState({ name: "" });
  const [countrySelectionOpened, setCountrySelectionOpened] = useState(false);
  const [countrySelected, setCountrySelected] = useState(null);
  const [enteredBilling, setEnteredBilling] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  // Fetch data
  const GET_BILLING_DATA = gql`
    query GetBillingData {
      billingData {
        billingAddress {
          id
          name
          country
        }
        prices {
          product
          price
        }
        vatRate
        promo {
          expiration
          type
          data
        }
      }
    }
  `;

  const { data, error } = useQuery(GET_BILLING_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching billing data error", error);
    captureException(error);
  }

  // handle Package selection
  const selectPackage = (coins) => {
    setSelectedPackage(coins);
  };

  useEffect(() => {
    if (data && !selectedPackage) {
      const firstPackage = data.billingData.prices[0].product;
      const coins = Number(firstPackage.split("_")[0]);

      setSelectedPackage(coins);
    }
  }, [data]);

  // handle Billing address input
  const handleInputChange = (e) => {
    let newBilling = { ...billing };
    newBilling[e.target.name] = e.target.value;

    setBilling(newBilling);
  };

  const toggleCountrySelection = () => {
    setCountrySelectionOpened((prevState) => !prevState);
  };

  const onCountrySelect = (country) => {
    setCountrySelected(country);
    setCountrySelectionOpened(false);

    const newWarnings = warnings.filter(
      (warning) => warning !== "billingCountry"
    );

    setWarnings(newWarnings);
  };

  const billingFormContinue = () => {
    setWarnings([]);

    let newWarnings = [];

    if (billing.name.trim().length < 5) newWarnings.push("billingName");
    if (!countrySelected) newWarnings.push("billingCountry");

    if (newWarnings.length > 0) {
      setWarnings(newWarnings);
      return;
    }

    setEnteredBilling(true);
    setTimeout(
      () =>
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        }),
      100
    );
  };

  const billingFormEdit = () => {
    setEnteredBilling(false);
  };

  // Get Stripe checkout URL
  const GET_STRIPE_CHECKOUT = gql`
    query GetStripeCheckout($pack: String!) {
      getStripeCheckout(pack: $pack) {
        url
      }
    }
  `;

  const [getStripeCheckout] = useLazyQuery(GET_STRIPE_CHECKOUT);

  // handle Stripe checkout
  const stripeCheckout = async () => {
    console.log("stripe clicked", checkoutLoading, selectedPackage);
    try {
      if (checkoutLoading) return;
      if (!selectedPackage) return;

      setCheckoutLoading(true);

      const packageName = selectedPackage + "_coins";
      console.log("packageName", packageName);

      // create checkout session
      const checkout = await getStripeCheckout({
        variables: {
          pack: packageName,
        },
        fetchPolicy: "network-only",
      });

      const { loading, error, data } = checkout;

      console.log("data", data);

      setCheckoutLoading(false);

      if (error) {
        console.log("Get stripe checkout error", error);
        captureException(error);
      }

      if (data && !loading) {
        if (data.getStripeCheckout?.url) {
          window.location = data.getStripeCheckout.url;
        }
      }
    } catch (error) {
      captureException(error);
      setCheckoutLoading(false);
      return;
    }
  };

  // Get Confirmo checkout URL
  const GET_CONFIRMO_CHECKOUT = gql`
    query GetConfirmoCheckout($pack: String!) {
      getConfirmoCheckout(pack: $pack) {
        url
      }
    }
  `;

  const [getConfirmoCheckout] = useLazyQuery(GET_CONFIRMO_CHECKOUT);

  // handle Confirmo checkout
  const confirmoCheckout = async () => {
    console.log("confirmo clicked", checkoutLoading, selectedPackage);
    try {
      if (checkoutLoading) return;
      if (!selectedPackage) return;

      setCheckoutLoading(true);

      const packageName = selectedPackage + "_coins";
      console.log("packageName", packageName);

      // create checkout session
      const checkout = await getConfirmoCheckout({
        variables: {
          pack: packageName,
        },
        fetchPolicy: "network-only",
      });

      const { loading, error, data } = checkout;

      console.log("data", data);

      setCheckoutLoading(false);

      if (data.getConfirmoCheckout.url) {
        window.location = data.getConfirmoCheckout.url;
      }

      if (error) {
        console.log("Get confirmo checkout error", error);
        captureException(error);
      }

      if (data && !loading) {
        if (data.getConfirmoCheckout?.url) {
          window.location = data.getConfirmoCheckout.url;
        }
      }
    } catch (error) {
      captureException(error);
      setCheckoutLoading(false);
      return;
    }
  };

  // Get Coingate checkout URL
  const GET_COINGATE_CHECKOUT = gql`
    query GetCoingateCheckout($pack: String!) {
      getCoingateCheckout(pack: $pack) {
        url
      }
    }
  `;

  const [getCoingateCheckout] = useLazyQuery(GET_COINGATE_CHECKOUT);

  // handle Coingate checkout
  const coingateCheckout = async () => {
    console.log("coingate clicked", checkoutLoading, selectedPackage);
    try {
      if (checkoutLoading) return;
      if (!selectedPackage) return;

      setCheckoutLoading(true);

      const packageName = selectedPackage + "_coins";
      console.log("packageName", packageName);

      // create checkout session
      const checkout = await getCoingateCheckout({
        variables: {
          pack: packageName,
        },
        fetchPolicy: "network-only",
      });

      const { loading, error, data } = checkout;

      console.log("data", data);

      setCheckoutLoading(false);

      if (error) {
        console.log("Get coingate checkout error", error);
        captureException(error);
      }

      if (data && !loading) {
        if (data.getCoingateCheckout?.url) {
          console.log("Will redirect to", data.getCoingateCheckout.url);
          //window.location = data.getCoingateCheckout.url;
        }
      }
    } catch (error) {
      captureException(error);
      setCheckoutLoading(false);
      return;
    }
  };

  const stripeDisabled = false;
  const confirmoDisabled = false;

  console.log("data", data);

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="230px" />
        </LoadingBox>
      )}
      {data && (
        <PurchaseCoins
          data={data}
          selectPackage={selectPackage}
          selectedPackage={selectedPackage}
          warnings={warnings}
          handleInputChange={handleInputChange}
          billing={billing}
          toggleCountrySelection={toggleCountrySelection}
          countrySelectionOpened={countrySelectionOpened}
          onCountrySelect={onCountrySelect}
          countrySelected={countrySelected}
          billingFormContinue={billingFormContinue}
          enteredBilling={enteredBilling}
          billingFormEdit={billingFormEdit}
          stripeDisabled={stripeDisabled}
          confirmoDisabled={confirmoDisabled}
          stripeCheckout={stripeCheckout}
          confirmoCheckout={confirmoCheckout}
          coingateCheckout={coingateCheckout}
        />
      )}
    </>
  );
};
