import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import SideStats from "./SideStats";

export default () => {
  // Fetch data
  const GET_SIDE_STATS_DATA = gql`
    query GetSideStatsData {
      sideStats {
        activePosts
        lastWeekImpressions
        previousWeekImpressions
        lastWeekInteractions
        previousWeekInteractions
        last7DaysStats {
          impressions
          interactions
        }
      }
    }
  `;

  const { data, error } = useQuery(GET_SIDE_STATS_DATA, {
    //fetchPolicy: "network-only",
    pollInterval: 300000,
  });

  if (error) {
    console.log("error fetching side stats", error);
    captureException(error);
  }

  //console.log("data", data);

  return <SideStats data={data} />;
};
