import styled from "styled-components";
import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { captureException } from "@sentry/react";

import { AUTH_ID, AUTH_TOKEN, AUTH_WS_TOKEN, SHARD_NUMBER } from "constants";
import DeleteAccount from "./DeleteAccount";
import Backdrop from "components/other/Backdrop";
import MessageWindow from "components/other/MessageWindow";

const ModalWrap = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export default () => {
  const [confirmDeleteForm, setConfirmDeleteForm] = useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const [errorWindow, setErrorWindow] = useState(false);
  const [deleteButtonBlock, setDeleteButtonBlock] = useState(false);

  const toggleDeleteAccountForm = () => {
    if (!confirmDeleteForm) {
      setDeleteButtonBlock(true);
      setTimeout(() => setDeleteButtonBlock(false), 3000);
    }

    setConfirmDeleteForm((prevState) => !prevState);
  };

  // handle Account Delete
  const DELETE_ACCOUNT = gql`
    mutation DeleteAccount {
      deleteAccount {
        resultCode
      }
    }
  `;

  const [deleteAccount] = useMutation(DELETE_ACCOUNT);

  const onDeleteAccount = async () => {
    try {
      if (deleteAccountLoading) return;

      setDeleteAccountLoading(true);

      const result = await deleteAccount();

      const { resultCode } = result.data.deleteAccount;
      console.log({ resultCode });

      setDeleteAccountLoading(false);

      if (resultCode !== 0) {
        openErrorWindow();
        return;
      }

      localStorage.removeItem(AUTH_ID);
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_WS_TOKEN);
      localStorage.removeItem(SHARD_NUMBER);

      window.location = "/";
    } catch (error) {
      captureException(error);
      console.log("onDeleteAccount error", error);
      setDeleteAccountLoading(false);
      openErrorWindow();
      return;
    }
  };

  // Error handlig
  const openErrorWindow = () => {
    setErrorWindow(true);
  };

  const handleModalClose = (modal) => {
    if (modal === "error") {
      setErrorWindow(false);
    }
  };

  return (
    <>
      <DeleteAccount
        toggleDeleteAccountForm={toggleDeleteAccountForm}
        confirmDeleteForm={confirmDeleteForm}
        onDeleteAccount={onDeleteAccount}
        deleteButtonBlock={deleteButtonBlock}
      />
      {errorWindow && (
        <ModalWrap>
          <Backdrop
            onClick={handleModalClose}
            parameters="error"
            background="rgba(0, 0, 0, 0.7)"
          />
          <MessageWindow handleModalClose={handleModalClose} type="error" />
        </ModalWrap>
      )}
    </>
  );
};
