import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import tipIcon from "assets/img/icons/icons8-info.svg";

const TipIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;

  &:hover {
    cursor: help;
  }
`;

const Icon = styled.img`
  width: 100%;
  padding-top: ${(props) => (props.paddingtop ? props.paddingtop : "1px")};
`;

export default ({ tip, paddingTop }) => {
  return (
    <>
      <TipIcon
        data-tooltip-id="linkTip"
        data-tooltip-html={tip}
        data-tooltip-place="top"
        data-tooltip-delay-show={500}
        onClick={(e) => e.stopPropagation()}
      >
        <Icon src={tipIcon} paddingtop={paddingTop} />
      </TipIcon>
      <Tooltip id="linkTip" className="tooltip" />
    </>
  );
};
