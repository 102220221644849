import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import * as css from "styles/CssVariables.js";

import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import PostListBox from "components/posts/PostListBox";
import CreateFirstPostBox from "components/dashboard/CreateFirstPostBox";
import CreateAnotherPostBox from "components/dashboard/CreateAnotherPostBox";
import LoadingBox from "components/other/LoadingBox";

const DasboardPosts = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const Posts = styled.div`
  width: 100%;

  display: flex;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const LinkWrap = styled.div``;

const ButtonWrap = styled(Link)`
  text-decoration: none;
`;

export default ({ data, loading }) => {
  const navigate = useNavigate();

  const posts = data ? data.posts.posts : [];

  const list = posts.map((post) => {
    const onPostClick = (id) => {
      const linkToPost = "/modify-post?id=" + id;

      navigate(linkToPost);
    };

    //return <PostListBox post={post} dashList={true} />;

    return (
      <LinkWrap onClick={() => onPostClick(post.id)} key={post.id}>
        <PostListBox post={post} dashList={true} />
      </LinkWrap>
    );
  });

  if (list.length === 1) {
    list.push(<CreateAnotherPostBox key="another-post-box" />);
  }

  if (list.length === 0) {
    list.push(<CreateFirstPostBox key="first-post-box" />);
  }

  return (
    <DasboardPosts>
      <Posts>
        {loading && (
          <LoadingBox>
            <BoxLoadingSpinner height="218px" />
          </LoadingBox>
        )}
        {!loading && list}
      </Posts>
      {posts.length > 0 && (
        <ButtonWrap to="/posts">
          <ButtonPrimary>View All Posts</ButtonPrimary>
        </ButtonWrap>
      )}
    </DasboardPosts>
  );
};
